body .form-validation {
  width: auto !important;
  margin-left: -32px !important;
  position: absolute !important;
  bottom: 7px !important;
  left: 100% !important;
  z-index: 999 !important;
  cursor: help;

  .validation-message {
    width: auto !important;
    max-width: 200px !important;
    padding: 10px !important;
    overflow: visible !important;
    border: 1px solid #B94A48 !important;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute !important;
    top: -9999em;
    right: 32px !important;
    z-index: 999 !important;
    line-height: normal !important;
    text-shadow: none !important;
    font-size: 13px !important;
    font-weight: normal !important;
    color: #B94A48 !important;
    white-space: nowrap !important;
    background: #F2DEDE !important;
  }
}

.form-validation:hover .validation-message {
  top: 0 !important;
}
